import { useFormik } from 'formik'
import React from 'react'
import { useHistory } from 'react-router'
import { LoginResponse } from '../models/auth'
import api from '../utils/api'

const Auth = () => {
	const history = useHistory()
	const { isSubmitting, status, handleChange, handleSubmit } = useFormik({
		initialValues: {
			password: '',
		},
		onSubmit: async (values, { setSubmitting, setStatus }) => {
			setStatus(null)

			try {
				const ret = await api.post<void, LoginResponse>('/auth/login', values)
				localStorage.setItem('x-master-token', ret.token)

				history.push('/dashboard')
			} catch ({ message }) {
				setStatus(message)
				setSubmitting(false)
			}
		},
	})
	return (
		<div className="main-content">
			<div className="container">
				<div className="row justify-content-center align-items-center vh-100">
					<div className="col-md-5 mb-5">
						<div className="navbar-brand text-center w-100 mb-3"><span>MSN</span> MASTER</div>
						<div className="card bg-secondary border-0 mb-0">
							<div className="card-body">
								{status && <div className="alert alert-danger">{status}</div>}
								<form autoComplete="off" onSubmit={handleSubmit}>
									<div className="form-group">
										<label className="form-control-label">รหัสผ่าน</label>
										<input
											type="password"
											name="password"
											className="form-control"
											placeholder="รหัสผ่าน ..."
											required
											onChange={handleChange}
										/>
									</div>
									<button type="submit" className="btn btn-primary mx-auto" disabled={isSubmitting}>
										เข้าสู่ระบบ
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Auth
