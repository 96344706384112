import moment from 'moment'
import React, { FC } from 'react'
import { Invoice } from '../../models/invoice'

interface PaymentProps {
	invoice: Invoice
}
const Payment: FC<PaymentProps> = ({ invoice }) => (
	<div className="invoice-info row">
		<div className="col-6">
			<address>
				<h3>ชำระเงินโดยการโอนเงินเข้าบัญชีธนาคารด้านล่าง</h3>
				ธนาคารกสิกรไทย
				<br />
				เลขที่บัญชี <strong>0963812531</strong>
				<br />
				ณัฐพงษ์ ภิญโญฤทธิ์
				<br />
			</address>
		</div>
		<div className="col-6 text-right">
			<address>
				<h3>โปรดชำระเงินก่อนวันที่</h3>
				{moment(invoice.expireAt).format('DD/MM/YYYY')}
			</address>
		</div>
	</div>
)

export default Payment
