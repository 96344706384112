import React, { FC } from 'react'
import InvoiceForm from './InvoiceForm'
import { Modal } from 'reactstrap'

interface CreateInvoiceProps {
	isOpenModal?: boolean

	refreshInvoice: () => void
	closeCreateModal: () => void
}
const CreateInvoice: FC<CreateInvoiceProps> = ({ refreshInvoice, isOpenModal, closeCreateModal }) => {
	return (
		<Modal isOpen={isOpenModal} className="modal-dialog-centered" fade={true} toggle={() => closeCreateModal()}>
			<div className="modal-body">
				<div className="d-flex justify-content-between align-items-center">
					<h1>ออกใบแจ้งหนี้</h1>
					<div className="modal-close">
						<i className="far fa-times" onClick={() => closeCreateModal()}></i>
					</div>
				</div>
				<InvoiceForm refreshInvoice={() => {
					refreshInvoice()
					closeCreateModal()
				}} />
			</div>
		</Modal>
	)
}

export default CreateInvoice
