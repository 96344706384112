import React, { FC } from 'react'
import { Invoice } from '../../models/invoice'

interface HeaderProps {
	invoice: Invoice
}
const Header: FC<HeaderProps> = ({ invoice }) => (
	<div className="top-header">
		<div className="d-flex">
			<div className="col-6 p-0 d-flex align-items-center">
				<img src="https://msn.cdnbet.co/invoice/images/logo-light.png" alt="Logo" className="logo" />
			</div>
			<div className="col-6 p-0 text-right">
				<div className="invoice-title">
					<h2 className="color-yellow">Invoice</h2>
					<p className="pull-right">เลขที่ใบแจ้งหนี้ #{invoice.id}</p>
				</div>
			</div>
		</div>
	</div>
)

export default Header
