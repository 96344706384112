import React, { FC, Fragment, ReactNode, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import api from '../../utils/api'

interface WrapeprProps {
	children: ReactNode
}
const AuthWrapper: FC<WrapeprProps> = (props) => {
	const history = useHistory()
	const [showChildren, setShowChildren] = useState(false)
	useEffect(() => {
		const makeInitialRequests = async () => {
			const requests = [api.get('/auth')]

			try {
				await Promise.all(requests)

				setShowChildren(true)
			} catch (_) {
				localStorage.removeItem('x-master-token')
				history.push('/login')
			}
		}

		const user = localStorage.getItem('x-master-token')
		if (!user) {
			history.push('/login')
		} else {
			makeInitialRequests()
		}
	}, [history, history.location.pathname])

	return <Fragment>{showChildren ? props.children : <div />}</Fragment>
}

export default AuthWrapper
