import { useFormik } from 'formik'
import React, { FC, useEffect } from 'react'
import Swal from 'sweetalert2'
import { Invoice } from '../../models/invoice'
import api from '../../utils/api'

interface InvoiceFormProps {
	prefix?: string

	refreshInvoice?: () => void
}
const InvoiceForm: FC<InvoiceFormProps> = ({ prefix, refreshInvoice }) => {
	const { values, setFieldValue, handleChange, handleSubmit } = useFormik({
		initialValues: {
			prefix: '',
			date: '',
			discount: '',
			monthlyFee: 30000,
		},
		onSubmit: async (values, { setSubmitting }) => {
			try {
				const res = await api.post<void, Invoice>('/invoices', values)

				refreshInvoice && refreshInvoice()
				window.open(`/invoices/${res.id}`, '_blank')
			} catch ({ message }) {
				Swal.fire('เกิดข้อผิดพลาด', message, 'error')
			} finally {
				setSubmitting(false)
			}
		},
	})
	useEffect(() => {
		if (prefix) {
			setFieldValue('prefix', prefix)
		}
	}, [prefix, setFieldValue])

	return (
		<form autoComplete="off" onSubmit={handleSubmit}>
			<div className="form-group">
				<label className="form-control-label">Prefix</label>
				<input
					type="text"
					name="prefix"
					className="form-control"
					placeholder="Prefix"
					value={values.prefix}
					required
					readOnly={prefix !== undefined}
					onChange={handleChange}
				/>
			</div>
			<div className="form-group">
				<label className="form-control-label">วันที่ออกใบแจ้งหนี้</label>
				<input
					type="date"
					name="date"
					className="form-control"
					placeholder="วันที่ออกใบแจ้งหนี้"
					required
					onChange={handleChange}
				/>
			</div>
			<div className="form-group">
				<label className="form-control-label">ส่วนลด</label>
				<input
					type="text"
					name="discount"
					className="form-control"
					placeholder="ส่วนลด"
					onChange={handleChange}
				/>
			</div>
			<div className="form-group">
				<label className="form-control-label">ค่าบริการรายเดือน</label>
				<input
					type="number"
					name="monthlyFee"
					className="form-control"
					placeholder="ค่าบริการรายเดือน"
					value={values.monthlyFee}
					onChange={handleChange}
				/>
			</div>
			<button type="submit" className="btn btn-primary mx-auto">
				ยืนยัน
			</button>
		</form>
	)
}

export default InvoiceForm
