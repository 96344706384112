import moment from 'moment'
import React, { FC } from 'react'
import { Bill } from '../../models/bill'

interface BillInfoProps {
	bill: Bill
}
const BillInfo: FC<BillInfoProps> = ({ bill }) => (
	<div className="invoice-info row">
		<div className="col-6">
			<address>
				<h3>&nbsp;</h3>
				&nbsp;
				<br />
			</address>
			<address>
				<h3>ออกใบแจ้งหนี้ให้กับ</h3>
				<span id="company_name">{bill.customer}</span>
			</address>
		</div>
		<div className="col-6 text-right">
			<address>
				<h3>วันที่ออกใบแจ้งหนี้</h3>
				{moment(bill.createdAt).format('DD/MM/YYYY')}
				<br />
			</address>
			<address>
				<h3>ชำระเงินให้กับ</h3>
				MSN Bet Co., Ltd
			</address>
		</div>
	</div>
)

export default BillInfo
