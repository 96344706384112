import React, { FC } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface PrefixStatusInfoProps {
    dataList: object
}
const PrefixStatusInfo: FC<PrefixStatusInfoProps> = ({ dataList }) => {
    Chart.register(ChartDataLabels);
    const dataArray = Object.entries(dataList);
    const online = dataArray.filter(([key, value]) => { return value.active === true })
    const offline = dataArray.filter(([key, value]) => { return value.active === false })

    const dataStatusChart = {
        labels: [
            'เปิดใช้งาน',
            'ปิดใช้งาน',
            ],
        datasets: [{
            label: 'Prefix Status',
            data: [online.length, offline.length],
            backgroundColor: [
                'rgba(1, 219, 155, 1)',
                'rgba(220, 53, 69, 1)',
            ],
            borderWidth: 0,
            hoverOffset: 0,
            weight: 0.4
        }]
    };

    const optionsStatusChart = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            },
            datalabels: {
                display: true,
                color: 'rgba(255, 255, 255, 0.9)',
                font: {
                    family: "'DB HelvethaicaX', 'Helvetica', 'Arial', sans-serif",
                    size: 24,
                }
            }
        }
    };

	return (
        <div className="card-info status-wrapper mb-3">
            <div className="doughnut-chart">
                <Doughnut data={dataStatusChart} options={optionsStatusChart} />
                <div className="doughnut-total">
                    <div><small>สถานะ</small></div>
                    <div className="title">เว็บไซต์</div>
                </div>
            </div>
            <div className="status-info">
                <h2 className="status-topic">สถานะเว็บไซต์</h2>
                <p  className="status-description">ทั้งหมด {dataArray.length} เว็บ</p>
                <div className="status-list online">
                    <div className="status-symbol"></div>
                    <div className="status-title">
                        <label>เปิดใช้งาน</label>
                        <h3>{online.length} <small>เว็บ</small></h3>
                    </div>
                </div>
                <div className="status-list offline">
                    <div className="status-symbol"></div>
                    <div className="status-title">
                        <label>ปิดใช้งาน</label>
                        <h3>{offline.length} <small>เว็บ</small></h3>
                    </div>
                </div>
            </div>
        </div>
	)
}

export default PrefixStatusInfo
