import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

const Sidebar = () => {
	useEffect(() => {
		if (window.innerWidth > 1200) {
			document.body.classList.add('g-sidenav-show', 'g-sidenav-pinned')
		}
	}, [])

	const toggleNavbar = () => {
		if (document.body.classList.contains('g-sidenav-show')) {
			document.body.classList.remove('g-sidenav-show', 'g-sidenav-pinned')
		} else {
			document.body.classList.add('g-sidenav-show', 'g-sidenav-pinned')
		}
	}

	return (
		<nav className="msn-sidebar sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-dark pt-4">
			<div className="scrollbar-inner">
				<div className="close-sidebar d-lg-none" onClick={() => toggleNavbar()}>
					<i className="fal fa-times"></i>
				</div>
				<div className="sidenav-header d-flex align-items-center">
					<div className="navbar-brand"><span>MSN</span> MASTER</div>
				</div>
				<div className="navbar-inner">
					<ul className="navbar-nav">
						<li className="nav-item">
							<Link to="/dashboard"
								className={"nav-link " + (useLocation().pathname === '/dashboard' ? 'msn-active' : '')}
							>
								<i className="fad fa-tachometer-alt-fastest"></i>
								<span className="nav-link-text">ภาพรวม</span>
							</Link>
							<Link to="/prefixes"
								className={"nav-link " + (useLocation().pathname === '/prefixes' ? 'msn-active' : '')}
							>
								<i className="fad fa-browser"></i>
								<span className="nav-link-text">เว็บไซต์ทั้งหมด</span>
							</Link>
							<Link to="/invoices"
								className={"nav-link " + (useLocation().pathname === '/invoices' ? 'msn-active' : '')}
							>
								<i className="fad fa-file-invoice-dollar"></i>
								<span className="nav-link-text">ใบแจ้งหนี้</span>
							</Link>
							<Link to="/bills"
								className={"nav-link " + (useLocation().pathname === '/bills' ? 'msn-active' : '')}
								>
								<i className="fad fa-receipt"></i>
								<span className="nav-link-text">ใบแจ้งหนี้ลูกค้า</span>
							</Link>
						</li>
						<li className="nav-item">
							<Link to="/logout" className="nav-link msn-bg-danger">
								<i className="fad fa-sign-out"></i>
								<span className="nav-link-text">ออกจากระบบ</span>
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	)
}

export default Sidebar
