import { useFormik } from 'formik'
import React, { FC } from 'react'
import Swal from 'sweetalert2'
import { Bill, BillItem } from '../../models/bill'
import api from '../../utils/api'

interface BillFormValues {
	customer: string
	date: string
	discount: string
	items: BillItem[]
}
interface BillFormProps {
	refreshBill: () => void
}
const BillForm: FC<BillFormProps> = ({ refreshBill }) => {
	const { values, isSubmitting, setFieldValue, handleChange, handleSubmit } = useFormik<BillFormValues>({
		initialValues: {
			customer: '',
			date: '',
			discount: '',
			items: [],
		},
		onSubmit: async (values, { setSubmitting }) => {
			for (let x of values.items) {
				x.amount = parseFloat(x.amount.toString())
			}

			try {
				const res = await api.post<void, Bill>('/bills', values)

				refreshBill()
				window.open(`/bills/${res.id}`, '_blank')
			} catch ({ message }) {
				Swal.fire('เกิดข้อผิดพลาด', message, 'error')
			} finally {
				setSubmitting(false)
			}
		},
	})
	const addItem = () => {
		setFieldValue('items', [
			...values.items,
			{
				description: '',
				amount: 0,
			},
		])
	}
	const clearItems = () => {
		setFieldValue('items', [])
	}

	return (
		<form autoComplete="off" onSubmit={handleSubmit}>
			<div className="form-group">
				<label className="form-control-label">ชื่อลูกค้า</label>
				<input
					type="text"
					name="customer"
					className="form-control"
					placeholder="ชื่อลูกค้า"
					required
					onChange={handleChange}
				/>
			</div>
			<div className="form-group">
				<label className="form-control-label">วันที่ออกใบแจ้งหนี้</label>
				<input
					type="date"
					name="date"
					className="form-control"
					placeholder="วันที่ออกใบแจ้งหนี้"
					required
					onChange={handleChange}
				/>
			</div>
			<div className="form-group">
				<label className="form-control-label">ส่วนลด</label>
				<input
					type="text"
					name="discount"
					className="form-control"
					placeholder="ส่วนลด"
					onChange={handleChange}
				/>
			</div>
			<div className="form-group border-top border-bottom mb-4">
				<div className="d-flex align-items-center pb-3">
					<label className="mb-0">รายการอื่น ๆ</label>
					<div className="ml-auto">
						<button type="button" className="btn btn-info btn-sm" onClick={() => addItem()}>
							เพิ่มรายการ
						</button>
						<button type="button" className="btn btn-outline-danger btn-sm" onClick={() => clearItems()}>
							ล้างรายการ
						</button>
					</div>
				</div>

				{values.items.map((_, i) => (
				<div className="row" key={i}>
					<div className="col-md-8 form-group">
						<label className="form-control-label">รายละเอียด</label>
						<input
							type="text"
							name={`items[${i}].description`}
							className="form-control"
							placeholder="รายละเอียด"
							onChange={handleChange}
							required
						/>
					</div>
					<div className="col-md-4 form-group">
						<label className="form-control-label">ราคา</label>
						<input
							type="text"
							name={`items[${i}].amount`}
							className="form-control"
							placeholder="ราคา"
							onChange={handleChange}
							required
						/>
					</div>
				</div>
			))}
			</div>
			<button type="submit" className="btn btn-primary mx-auto" disabled={isSubmitting}>
				ยืนยัน
			</button>
		</form>
	)
}

export default BillForm
