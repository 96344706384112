import 'moment/locale/th'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './app.scss'
import './assets/scss/argon-dashboard-pro-react.scss'
import './assets/scss/msn-theme.scss'

declare global {
	interface Window {
		html2pdf: any
	}
}

ReactDOM.render(<App />, document.getElementById('root'))
