export const exportMemberOptions: Record<string, string> = {
	phone: 'เบอร์โทรศัพท์',
	name: 'ชื่อสมาชิก',
	bankCode: 'ชื่อธนาคาร',
	bankNumber: 'เลขที่บัญชี',
}

export interface ExportResponse {
	downloadURL: string
}
