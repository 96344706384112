import { useFormik } from 'formik'
import React, { FC, Fragment, useEffect } from 'react'
import swal from 'sweetalert2'
import { APIResponse } from '../../models/api'
import { Prefix, PrefixFeatureTypes, PrefixGameMetaTypes } from '../../models/prefix'
import api from '../../utils/api'

interface GameMetaGroupProps {
	gameMeta?: Record<PrefixGameMetaTypes, string>
	handleChange: (e: React.ChangeEvent<any>) => void
}
const SuperlotGroup: FC<GameMetaGroupProps> = ({ gameMeta, handleChange }) => (
	<Fragment>
		<div className="form-group">
			<label className="form-control-label">Superlot Token</label>
			<input
				type="text"
				name="gameMeta.secret"
				className="form-control"
				placeholder="Superlot Token"
				value={gameMeta?.secret}
				onChange={handleChange}
			/>
		</div>
		<div className="form-group">
			<label className="form-control-label">Superlot Member Copy Setting</label>
			<input
				type="text"
				name="gameMeta.registerSettings"
				className="form-control"
				placeholder="Superlot Member Copy Setting"
				value={gameMeta?.registerSettings}
				onChange={handleChange}
			/>
		</div>
	</Fragment>
)

const UsunGroup: FC<GameMetaGroupProps> = ({ gameMeta, handleChange }) => (
	<Fragment>
		<div className="form-group">
			<label className="form-control-label">Usun Key</label>
			<input
				type="text"
				name="gameMeta.key"
				className="form-control"
				placeholder="Usun Key"
				value={gameMeta?.key}
				onChange={handleChange}
			/>
		</div>
	</Fragment>
)

const AskmenetGroup: FC<GameMetaGroupProps> = ({ gameMeta, handleChange }) => (
	<Fragment>
		<div className="form-group">
			<label className="form-control-label">Askmebet Key</label>
			<input
				type="text"
				name="gameMeta.key"
				className="form-control"
				placeholder="Askmebet Key"
				value={gameMeta?.key}
				onChange={handleChange}
			/>
		</div>
		<div className="form-group">
			<label className="form-control-label">Askmebet Hash</label>
			<input
				type="text"
				name="gameMeta.hash"
				className="form-control"
				placeholder="Askmebet Hash"
				value={gameMeta?.hash}
				onChange={handleChange}
			/>
		</div>
	</Fragment>
)

const JokerTechGroup: FC<GameMetaGroupProps> = ({ gameMeta, handleChange }) => (
	<Fragment>
		<div className="form-group">
			<label className="form-control-label">Secret</label>
			<input
				type="text"
				name="gameMeta.secret"
				className="form-control"
				placeholder="Secret"
				value={gameMeta?.secret}
				onChange={handleChange}
			/>
		</div>
	</Fragment>
)

const BetflixGroup: FC<GameMetaGroupProps> = ({ gameMeta, handleChange }) => (
	<Fragment>
		<div className="form-group">
			<label className="form-control-label">API Key</label>
			<input
				type="text"
				name="gameMeta.key"
				className="form-control"
				placeholder="API Key"
				value={gameMeta?.key}
				onChange={handleChange}
			/>
		</div>
		<div className="form-group">
			<label className="form-control-label">API Secret</label>
			<input
				type="text"
				name="gameMeta.secret"
				className="form-control"
				placeholder="API Secret"
				value={gameMeta?.secret}
				onChange={handleChange}
			/>
		</div>
	</Fragment>
)

const SuperSlotGroup: FC<GameMetaGroupProps> = ({ gameMeta, handleChange }) => (
	<div className="form-group">
		<label className="form-control-label">Password</label>
		<input
			type="text"
			name="gameMeta.password"
			className="form-control"
			placeholder="Password"
			value={gameMeta?.password}
			onChange={handleChange}
		/>
	</div>
)

const PGSlotGroup: FC<GameMetaGroupProps> = ({ gameMeta, handleChange }) => (
	<div className="form-group">
		<label className="form-control-label">Secret</label>
		<input
			type="text"
			name="gameMeta.secret"
			className="form-control"
			placeholder="Secret"
			value={gameMeta?.secret}
			onChange={handleChange}
		/>
	</div>
)

const UFABetGroup: FC<GameMetaGroupProps> = ({ gameMeta, handleChange }) => (
	<Fragment>
		<div className="form-group">
			<label className="form-control-label">Password</label>
			<input
				type="text"
				name="gameMeta.password"
				className="form-control"
				placeholder="Password"
				value={gameMeta?.password}
				onChange={handleChange}
			/>
		</div>
		<div className="form-group">
			<label className="form-control-label">Register Settings</label>
			<input
				type="text"
				name="gameMeta.registerSettings"
				className="form-control"
				placeholder="Register Settings"
				value={gameMeta?.registerSettings}
				onChange={handleChange}
			/>
		</div>
	</Fragment>
)

const AMBSuperAPIGroup: FC<GameMetaGroupProps> = ({ gameMeta, handleChange }) => (
	<Fragment>
		<div className="form-group">
			<label className="form-control-label">Secret</label>
			<input
				type="text"
				name="gameMeta.secret"
				className="form-control"
				placeholder="Secret"
				value={gameMeta?.secret}
				onChange={handleChange}
			/>
		</div>
		<div className="form-group">
			<label className="form-control-label">Product</label>
			<select name="gameMeta.game" className="form-control" required value={gameMeta?.game} onChange={handleChange}>
				<option defaultChecked>--- เลือกเกม ---</option>
				<option value="PGSOFT">PG Soft</option>
				<option value="SLOTXO">SlotXO</option>
				<option value="ASKMEBET">Askmebet</option>
				<option value="AMBPOKER">Ambpoker</option>
				<option value="AMBSLOT">AMB Slot</option>
				<option value="HABANERO">Habanero</option>
				<option value="SPADE">Spade Gaming</option>
				<option value="SIMPLEPLAY">Simple Play</option>
				<option value="ICONIC">Iconic Gaming</option>
				<option value="MEGA">Mega888</option>
				<option value="XIN">Xin Gaming</option>
				<option value="LIVE22">Live22</option>
				<option value="GAMATRON">Gamatron</option>
				<option value="ALLWAYSPIN">Allwayspin</option>
				<option value="FUN">Fun Gaming</option>
				<option value="EURASIAN">Eurasian Gaming</option>
				<option value="JDB">JDB</option>
				<option value="WAZDAN">Wazdan</option>
				<option value="EVOPLAY">Evoplay</option>
				<option value="ENDORPHINA">Endorphina</option>
				<option value="CQ9">CQ9</option>
				<option value="KAGAME">KA Gaming</option>
				<option value="JILI">JILI</option>
				<option value="JOKER">Joker123</option>
				<option value="MANNA">Mannaplay</option>
				<option value="TIGERGAME">Tigergame</option>
				<option value="ROYAL">Royal Slot Gaming</option>
				<option value="GIOCO">Gioco Plus</option>
				<option value="SEXY">(Casino) Sexy Gaming</option>
				<option value="PRAGMATIC">(Casino) Pragmatic Play</option>
				<option value="BIGGAME">(Casino) Big Gaming</option>
				<option value="DREAM">(Casino) Dream Gaming</option>
				<option value="BETGAME">(Casino) BetgamesTV</option>
				<option value="GCLUB">(Casino) Royal GClub</option>
				<option value="EBET">(Casino) Ebet</option>
				<option value="ALLBET">(Casino) AllBet</option>
				<option value="AGGAME">(Casino) Asia Gaming AG</option>
				<option value="PRETTY">(Casino) Pretty Gaming</option>
				<option value="SAGAME">(Casino) SA Gaming</option>
				<option value="XTREME">(Casino) Xtreme Gaming</option>
			</select>
		</div>
	</Fragment>
)

const GClub168Group: FC<GameMetaGroupProps> = ({ gameMeta, handleChange }) => (
	<Fragment>
		<div className="form-group">
			<label className="form-control-label">Password</label>
			<input
				type="text"
				name="gameMeta.password"
				className="form-control"
				placeholder="Password"
				value={gameMeta?.password}
				onChange={handleChange}
			/>
		</div>
	</Fragment>
)

interface FeatureOptionsProps {
	features: Record<PrefixFeatureTypes, string>
	prefillFeatures?: Record<PrefixFeatureTypes, boolean>
	updateFeatureField: (key: string, value: boolean) => void
}
const FeatureOptions: FC<FeatureOptionsProps> = ({ features, prefillFeatures, updateFeatureField }) => (
	<div className="form-group">
		{Object.entries(features).map(([key, value]) => (
			<div className="mb-2" key={key}>
				<label className="msn-checkbox">
					{value}
					<input
						type="checkbox"
						name={`features.${key}`}
						defaultChecked={prefillFeatures && prefillFeatures[key as PrefixFeatureTypes]}
						onClick={(e) => updateFeatureField(`features.${key}`, e.currentTarget.checked)}
					/>
					<span className="checkmark"></span>
				</label>
			</div>
		))}
	</div>
)

interface PrefixFormProps {
	prefill?: Prefix
	features: Record<PrefixFeatureTypes, string>

	onUpdate?: () => void
}
const PrefixForm: FC<PrefixFormProps> = ({ prefill, features, onUpdate }) => {
	const prefillFeatures = {} as Record<PrefixFeatureTypes, boolean>
	Object.keys(features).forEach((key) => {
		prefillFeatures[key as PrefixFeatureTypes] = prefill?.features[key as PrefixFeatureTypes] ?? false
	})

	interface PrefixFormValues {
		prefix: string
		game: string
		agent: string
		gameMeta: Record<PrefixGameMetaTypes, string>
		features: Record<PrefixFeatureTypes, boolean>
	}
	const { values, isSubmitting, setFieldValue, handleChange, handleSubmit } = useFormik<PrefixFormValues>({
		initialValues: {
			prefix: '',
			game: '',
			agent: '',
			gameMeta: {} as Record<PrefixGameMetaTypes, string>,
			features: {} as Record<PrefixFeatureTypes, boolean>,
		},
		onSubmit: async (values, { setSubmitting }) => {
			try {
				const res = await api.post<void, APIResponse>(`/prefixes/${values.prefix}`, values)
				onUpdate && onUpdate()
				swal.fire('ดำเนินการสำเร็จ', res.message, 'success')
			} catch ({ message }) {
				swal.fire('เกิดข้อผิดพลาด', message as string, 'error')
			} finally {
				setSubmitting(false)
			}
		},
	})
	useEffect(() => {
		if (prefill) {
			setFieldValue('prefix', prefill.prefix)
			setFieldValue('game', prefill.config.game)
			setFieldValue('agent', prefill.config.agent)
			setFieldValue('gameMeta', prefill.config.gameMeta)
			setFieldValue('features', prefill.features)
		}
	}, [prefill, setFieldValue])

	return (
		<form autoComplete="off" onSubmit={handleSubmit}>
			<div className="form-group">
				<label className="form-control-label">Prefix</label>
				<input
					type="text"
					name="prefix"
					className="form-control"
					placeholder="Prefix"
					value={values.prefix}
					required
					readOnly={prefill !== undefined}
					onChange={handleChange}
				/>
			</div>
			<div className="form-group">
				<label className="form-control-label">Game</label>
				<div className="msn-select">
					<select name="game" className="form-control" required value={values.game} onChange={handleChange}>
						<option defaultChecked>--- เลือกเกม ---</option>
						<option value="ambsuperapi">AMB Super API</option>
						<option value="pgslot">PGSlot</option>
						<option value="joker123">Joker123</option>
						<option value="slotxo">SlotXO</option>
						<option value="betflix">Betflix</option>
						<option value="gclub168">GClub168</option>
						<option value="ambbet">Ambbet</option>
						<option value="wingbet">Wingbet</option>
						<option value="2goalbet">2Goalbet</option>
						<option value="kiss918">Kiss918</option>
						<option value="pussy888">Pussy888</option>
						<option value="ufa">UFABET</option>
						<option value="superslot">SuperSlot</option>
						<option value="usun">Usun</option>
						<option value="superlot">Superlot</option>
					</select>
					<i className="far fa-chevron-down"></i>
				</div>
			</div>
			<div className="form-group">
				<label className="form-control-label">Agent</label>
				<input
					type="text"
					name="agent"
					className="form-control"
					placeholder="Agent"
					required
					value={values.agent}
					onChange={handleChange}
				/>
			</div>
			{(values.game === 'ambbet' || values.game === 'wingbet' || values.game === '2goalbet') && (
				<AskmenetGroup gameMeta={values.gameMeta} handleChange={handleChange} />
			)}
			{(values.game === 'joker123' || values.game === 'slotxo') && (
				<JokerTechGroup gameMeta={values.gameMeta} handleChange={handleChange} />
			)}
			{values.game === 'betflix' && <BetflixGroup gameMeta={values.gameMeta} handleChange={handleChange} />}
			{values.game === 'superslot' && <SuperSlotGroup gameMeta={values.gameMeta} handleChange={handleChange} />}
			{values.game === 'ufa' && <UFABetGroup gameMeta={values.gameMeta} handleChange={handleChange} />}
			{values.game === 'pgslot' && <PGSlotGroup gameMeta={values.gameMeta} handleChange={handleChange} />}
			{values.game === 'ambsuperapi' && <AMBSuperAPIGroup gameMeta={values.gameMeta} handleChange={handleChange} />}
			{values.game === 'gclub168' && <GClub168Group gameMeta={values.gameMeta} handleChange={handleChange} />}
			{values.game === 'usun' && <UsunGroup gameMeta={values.gameMeta} handleChange={handleChange} />}
			{values.game === 'superlot' && <SuperlotGroup gameMeta={values.gameMeta} handleChange={handleChange} />}

			<FeatureOptions features={features} prefillFeatures={prefillFeatures} updateFeatureField={setFieldValue} />

			<button type="submit" className="btn btn-primary mx-auto" disabled={isSubmitting}>
				ยืนยัน
			</button>
		</form>
	)
}

export default PrefixForm
