import React, { FC } from 'react';
import { Progress } from 'reactstrap';

interface PrefixFeatureInfoProps {
    dataList: object
    featureList: object
}

const PrefixFeatureInfo: FC<PrefixFeatureInfoProps> = ({ dataList, featureList }) => {
    const dataArray = Object.entries(dataList);
    let numberOfFeature: any = {};
    let items:any = [];
    let featureName: any = [];
    let featureNum: any = [];
    let featureColor: any = ['primary', 'success', 'light', 'light', 'danger', 'warning']

    Object.keys(featureList).forEach((key) => numberOfFeature[key] = 0);

    dataArray.forEach(([key, value]) => {
        Object.entries(value.features).forEach(([key, value]) => {
            numberOfFeature[key] = value ? numberOfFeature[key] + 1 : numberOfFeature[key];
        })
    });

    Object.entries(featureList).filter(([key, value]) => { return featureName.push(value) })
    Object.entries(numberOfFeature).filter(([key, value]) => { return featureNum.push(value) })

    for (let i = 0; i < featureName.length; i++) {
        items.push(
            <div className="feature-bar" key={i}>
                <div className="d-flex mb-1">
                    <div>{featureName[i]}</div>
                    <div className="ml-auto">{featureNum[i]} / {dataArray.length}</div>
                </div>
                <Progress animated value={(featureNum[i] / dataArray.length) * 100} color={featureColor[i]}>
                    {((featureNum[i] / dataArray.length) * 100).toFixed(2)}%
                </Progress>
            </div>
        );
    }

	return <div className="card-info mb-3">{items}</div>
}

export default PrefixFeatureInfo
