import React, { FC } from 'react'
import { Bill, BillInternal } from '../../models/bill'
import { format } from '../../utils/number'

interface DetailProps {
	bill: Bill & BillInternal
}
const Detail: FC<DetailProps> = ({ bill }) => (
	<div className="invoice-info row">
		<div className="col-12">
			<div className="panel panel-default">
				<div className="panel-body">
					<div>
						<table className="table">
							<thead>
								<tr>
									<td>
										<strong>รายละเอียด</strong>
									</td>
									<td className="no-line" />
									<td className="text-right">
										<strong>ราคา</strong>
									</td>
								</tr>
							</thead>
							<tbody>
								{bill.items
									.filter((item) => item.amount > 0)
									.map((item) => (
										<tr key={item.description}>
											<td>{item.description}</td>
											<td className="no-line" />
											<td className="text-right">
												฿ <b>{format(item.amount)}</b>
											</td>
										</tr>
									))}

								<tr className="total">
									<td className="no-line border-top-0" />
									<td className="no-line text-right bd-1">
										<strong>รวม</strong>
									</td>
									<td className="no-line text-right bd-1">{format(bill.sum)}</td>
								</tr>
								{bill.discount > 0 && (
									<tr className="total">
										<td className="no-line border-top-0" />
										<td className="no-line text-right bd-1">
											<strong>ส่วนลด</strong>
										</td>
										<td className="no-line text-right bd-1">{format(bill.discount)}</td>
									</tr>
								)}
								<tr className="total-amount">
									<td className="no-line border-top-0" />
									<td className="no-line text-right bd-1">
										<strong>ยอดที่ต้องชำระ</strong>
									</td>
									<td className="no-line text-right bd-1">{format(bill.total)}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<hr className="mt-4 mb-0" />
				</div>
			</div>
		</div>
	</div>
)

export default Detail
