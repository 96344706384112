import moment from 'moment'
import React, { ChangeEvent, FC, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Invoice, InvoiceFilter } from '../../models/invoice'
import { format } from '../../utils/number'

interface InvoiceStatusBadgeProps {
	status: string
}
const InvoiceStatusBadge: FC<InvoiceStatusBadgeProps> = ({ status }) => {
	switch (status) {
		case 'pending': {
			return <span className="d-block badge badge-warning">รอชำระเงิน</span>
		}
		case 'confirmed': {
			return <span className="d-block badge badge-success">ชำระเงินแล้ว</span>
		}
		case 'cancelled': {
			return <span className="d-block badge badge-danger">ยกเลิก</span>
		}
		default: {
			return <span className="d-block badge badge-light">{status}</span>
		}
	}
}

interface InvoiceListProps {
	filter: InvoiceFilter
	invoices: Invoice[]

	openCreateModal: () => void
	changeFilter: (v: InvoiceFilter) => void
	confirmInvoice: (invoiceID: number) => void
	cancelInvoice: (invoiceID: number) => void
}
const InvoiceList: FC<InvoiceListProps> = ({ filter, invoices, changeFilter, confirmInvoice, cancelInvoice, openCreateModal }) => (
	<div className="card msn-card">
		<div className="card-header">
			<h1 className="text-white mb-0">ใบแจ้งหนี้ทั้งหมด</h1>
			<button type="button"
				className="btn btn-primary my-2 my-lg-0 mx-0 ml-lg-3"
				data-toggle="modal"
				data-target="#addPrefix"
				onClick={() => openCreateModal()}
			>
				<i className="fal fa-plus"></i><span>เพิ่มใบแจ้งนี้</span>
			</button>
			<div className="row ml-auto">
				<div className="col-md-6">
					<input
						type="month"
						className="form-control"
						required
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							changeFilter({
								...filter,
								date: e.currentTarget.value,
							})
						}}
					/>
				</div>
				<div className="col-md-6">
					<select
						className="form-control mw-150px"
						onChange={(e: ChangeEvent<HTMLSelectElement>) => {
							changeFilter({
								...filter,
								status: e.currentTarget.value,
							})
						}}
					>
						<option value="">ทั้งหมด</option>
						<option value="pending">ยังไม่จ่าย</option>
						<option value="confirmed">จ่ายแล้ว</option>
						<option value="cancelled">ยกเลิก</option>
					</select>
				</div>
			</div>
		</div>
		<div className="table-responsive msn-table">
			<table className="table">
				<thead>
					<tr>
						<th className="w-first-col">เลขที่ใบแจ้งหนี้</th>
						<th>สถานะ</th>
						<th>Prefix</th>
						<th>จำนวนเงิน</th>
						<th>วันที่ออกใบแจ้งหนี้</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{invoices.length <= 0 ? (
						<tr>
							<td colSpan={5} className="text-center">
								ไม่มีข้อมูล
							</td>
						</tr>
					) : (
						invoices.map((row) => (
							<tr key={row.id}>
								<td>{row.id}</td>
								<td><InvoiceStatusBadge status={row.status} /></td>
								<td>{row.prefix}</td>
								<td>{format(row.amount, 0)}</td>
								<td>{moment(row.createdAt).format('YYYY-MM-DD')}</td>
								<td>
									{row.status === 'pending' && (
										<Fragment>
											<button
												type="button"
												className="btn btn-success btn-sm"
												onClick={() => confirmInvoice(row.id)}
											>
												จ่ายแล้ว
											</button>
											<button
												type="button"
												className="btn btn-danger btn-sm"
												onClick={() => cancelInvoice(row.id)}
											>
												ยกเลิก
											</button>
										</Fragment>
									)}
									<Link to={`/invoices/${row.id}`} target="_blank" className="btn btn-info btn-sm">
										ดูใบแจ้งหนี้
									</Link>
								</td>
							</tr>
						))
					)}
				</tbody>
			</table>
		</div>
	</div>
)

export default InvoiceList
