import moment from 'moment'
import React, { FC, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Bill } from '../../models/bill'
import { format } from '../../utils/number'

interface BillStatusBadgeProps {
	status: string
}
const BillStatusBadge: FC<BillStatusBadgeProps> = ({ status }) => {
	switch (status) {
		case 'pending': {
			return <span className="d-block badge badge-warning">รอชำระเงิน</span>
		}
		case 'confirmed': {
			return <span className="d-block badge badge-success">ชำระเงินแล้ว</span>
		}
		case 'cancelled': {
			return <span className="d-block badge badge-danger">ยกเลิก</span>
		}
		default: {
			return <span className="d-block badge badge-light">{status}</span>
		}
	}
}

interface BillListProps {
	bills: Bill[]

	openCreateModal: () => void
	confirmBill: (billID: number) => void
	cancelBill: (billID: number) => void
}
const BillList: FC<BillListProps> = ({ bills, confirmBill, cancelBill, openCreateModal }) => {
	return (
		<div className="card msn-card">
			<div className="card-header">
				<h1 className="text-white mb-0">ใบแจ้งหนี้ทั้งหมด</h1>
				<button type="button"
					className="btn btn-primary my-2 my-lg-0 mx-0 ml-lg-3"
					data-toggle="modal"
					data-target="#addPrefix"
					onClick={() => openCreateModal()}
				>
					<i className="fal fa-plus"></i><span>เพิ่มใบแจ้งนี้</span>
				</button>
				<p className="text-white ml-auto mb-0">(1 เดือนล่าสุด)</p>
			</div>
			<div className="table-responsive msn-table">
				<table className="table">
					<thead>
						<tr>
							<th className="w-first-col">เลขที่ใบแจ้งหนี้</th>
							<th>สถานะ</th>
							<th>ชื่อลูกค้า</th>
							<th>จำนวนเงิน</th>
							<th>วันที่ออกใบแจ้งหนี้</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{bills.length <= 0 ? (
							<tr>
								<td colSpan={5} className="text-center">
									ไม่มีข้อมูล
								</td>
							</tr>
						) : (
							bills.map((row) => (
								<tr key={row.id}>
									<td>{row.id}</td>
									<td><BillStatusBadge status={row.status} /></td>
									<td>{row.customer}</td>
									<td>{format(row.amount, 0)}</td>
									<td>{moment(row.createdAt).format('YYYY-MM-DD')}</td>
									<td>
										{row.status === 'pending' && (
											<Fragment>
												<button
													type="button"
													className="btn btn-success btn-sm"
													onClick={() => confirmBill(row.id)}
												>
													จ่ายแล้ว
												</button>
												<button
													type="button"
													className="btn btn-danger btn-sm"
													onClick={() => cancelBill(row.id)}
												>
													ยกเลิก
												</button>
											</Fragment>
										)}
										<Link to={`/bills/${row.id}`} target="_blank" className="btn btn-info btn-sm">
											ดูใบแจ้งหนี้
										</Link>
									</td>
								</tr>
							))
						)}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default BillList
