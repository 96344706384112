import React, { useCallback, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import CreateInvoice from '../../components/Invoices/CreateInvoice'
import InvoiceList from '../../components/Invoices/InvoiceList'
import { APIResponse } from '../../models/api'
import { Invoice, InvoiceFilter } from '../../models/invoice'
import api from '../../utils/api'

const Invoices = () => {
	const [filter, setFilter] = useState<InvoiceFilter>({
		status: '',
		date: '',
	})
	const [invoices, setInvoices] = useState<Invoice[]>([])
	const [createInvoiceModal, setCreateInvoiceModal] = useState(false);
	const toggle = () => setCreateInvoiceModal(!createInvoiceModal)

	const fetchInvoices = useCallback(async (filter: InvoiceFilter) => {
		try {
			const res = await api.get<void, Invoice[]>('/invoices', {
				params: filter,
			})
			setInvoices(res)
		} catch ({ message }) {
			Swal.fire('เกิดข้อผิดพลาด', message, 'error')
		}
	}, [])
	useEffect(() => {
		fetchInvoices(filter)
	}, [fetchInvoices, filter])

	const confirmInvoice = async (invoiceID: number) => {
		const result = await Swal.fire({
			title: `ต้องการยืนยันใบแจ้งหนี้ #${invoiceID}?`,
			showCancelButton: true,
		})
		if (!result.isConfirmed) {
			return false
		}

		try {
			const res = await api.post<void, APIResponse>(
				`/invoices/${invoiceID}/confirm`
			)

			fetchInvoices(filter)
			Swal.fire('ดำเนินการสำเร็จ', res.message, 'success')
		} catch ({ message }) {
			Swal.fire('เกิดข้อผิดพลาด', message, 'error')
		}
	}
	const cancelInvoice = async (invoiceID: number) => {
		const result = await Swal.fire({
			title: `ต้องการยกเลิกใบแจ้งหนี้ #${invoiceID}?`,
			showCancelButton: true,
		})
		if (!result.isConfirmed) {
			return false
		}

		try {
			const res = await api.post<void, APIResponse>(
				`/invoices/${invoiceID}/cancel`
			)

			fetchInvoices(filter)
			Swal.fire('ดำเนินการสำเร็จ', res.message, 'success')
		} catch ({ message }) {
			Swal.fire('เกิดข้อผิดพลาด', message, 'error')
		}
	}

	return (
		<div className="row">
			<div className="col-md-12">
				<InvoiceList
					filter={filter}
					invoices={invoices}
					openCreateModal={() => toggle()}
					changeFilter={setFilter}
					confirmInvoice={confirmInvoice}
					cancelInvoice={cancelInvoice}
				/>
			</div>

			<CreateInvoice
				refreshInvoice={() => fetchInvoices(filter)}
				isOpenModal={createInvoiceModal}
				closeCreateModal={() => toggle()}
			/>
		</div>
	)
}

export default Invoices
