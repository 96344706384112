import axios, { AxiosError } from 'axios'
import { API_ENDPOINT } from '../constant'

const instance = axios.create({
	baseURL: API_ENDPOINT,
})

instance.interceptors.request.use((config) => {
	config.headers['Authorization'] = localStorage.getItem('x-master-token')
	return config
})
instance.interceptors.response.use(
	(res) => res.data,
	(e: AxiosError) => {
		if (e.response) {
			return Promise.reject(e.response.data)
		}
		return Promise.reject(e)
	}
)

export default instance
