import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Swal from 'sweetalert2'
import BillInfo from '../../components/BillView/BillInfo'
import Detail from '../../components/BillView/Detail'
import Header from '../../components/BillView/Header'
import Payment from '../../components/BillView/Payment'
import { Bill, BillInternal } from '../../models/bill'
import api from '../../utils/api'
import './bill.scss'

interface BillViewParams {
	billID: string
}
const BillView = () => {
	const params = useParams<BillViewParams>()
	const [bill, setBill] = useState<(Bill & BillInternal) | null>(null)
	const getInvoice = useCallback(async () => {
		try {
			const res = await api.get<void, Bill & BillInternal>(`/bills/${params.billID}`)

			const discountItems = res.items.filter((item) => item.amount < 0)
			res.discount = discountItems.length > 0 ? discountItems[0].amount * -1 : 0
			res.sum = res.items.filter((item) => item.amount > 0).reduce<number>((v, current) => v + current.amount, 0)
			res.total = res.items.reduce<number>((v, current) => v + current.amount, 0)
			setBill(res)
		} catch ({ message }) {
			Swal.fire('เกิดข้อผิดพลาด', message, 'error')
		}
	}, [params])
	useEffect(() => {
		getInvoice()
	}, [getInvoice])

	const convertToPDF = () => {
		window
			.html2pdf()
			.set({
				filename: `${bill?.customer}-${moment().format('YYYY-MM-DD')}.pdf`,
				html2canvas: {
					scale: 4,
				},
			})
			.from(document.getElementById('bill'))
			.save()
	}

	if (!bill) {
		return <div>Loading ...</div>
	}
	return (
		<div className="container">
			<div className="wrapper">
				<div id="bill" className="invoice">
					<Header bill={bill} />
					<BillInfo bill={bill} />
					<Detail bill={bill} />
					<Payment bill={bill} />
				</div>
			</div>

			<div className="btn-bottom">
				<button type="button" className="btn btn-light" onClick={() => convertToPDF()}>
					Download PDF
				</button>
			</div>
		</div>
	)
}

export default BillView
