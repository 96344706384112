import React, { Fragment, useEffect, useState, useCallback } from 'react'
import { Prefix, PrefixFeatureTypes } from '../../models/prefix'
import { Invoice } from '../../models/invoice'
import PrefixStatusInfo from '../../components/Dashboard/PrefixStatusInfo'
import PrefixGameInfo from '../../components/Dashboard/PrefixGameInfo'
import PrefixFeatureInfo from '../../components/Dashboard/PrefixFeatureInfo'
import InvoiceAmountInfo from '../../components/Dashboard/InvoiceAmountInfo'
import api from '../../utils/api'
import swal from 'sweetalert2'

const Dashboard = () => {
    const [prefixes, setPrefixes] = useState<Prefix[]>([])
    const [features, setFeatures] = useState<Record<PrefixFeatureTypes, string>>()
    const [invoices, setInvoices] = useState<Invoice[]>([])

    const fetchData = useCallback(async () => {
		try {
			const res = await Promise.all([
				api.get<void, Prefix[]>('/prefixes'),
                api.get<void, Record<PrefixFeatureTypes, string>>('/features'),
                api.get<void, Invoice[]>('/invoices'),
			])

			setPrefixes(res[0].sort((a, b) => (a.active < b.active ? 1 : -1)))
            setFeatures(res[1])
            setInvoices(res[2])
		} catch ({ message }) {
			swal.fire('เกิดข้อผิดพลาด', message as string, 'error')
		}
	}, [])

	useEffect(() => {
		fetchData()
	}, [fetchData])

    if (!prefixes || !invoices || !features) {
		return <div>Loading ...</div>
	}

    return (
        <Fragment>
            <div className="row">
				<div className="col-md-12">
                    <div className="card msn-card">
                        <div className="card-header">
                            <h1 className="text-white">ภาพรวม</h1>
                        </div>
                        <div className="row msn-info mb-4">
                            <div className="col-lg-6 col-xl-8">
                                <PrefixGameInfo dataList={prefixes} />
                                <InvoiceAmountInfo dataList={invoices} />
                            </div>
                            <div className="col-lg-6 col-xl-4">
                                <PrefixStatusInfo dataList={prefixes} />
                                <PrefixFeatureInfo
                                    dataList={prefixes}
                                    featureList={features}
                                />
                            </div>
                        </div>
                    </div>
				</div>
			</div>
        </Fragment>
    )
}

export default Dashboard