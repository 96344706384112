import Fuse from 'fuse.js'
import React, { FC, useEffect, useState } from 'react'
import swal from 'sweetalert2'
import { APIResponse } from '../../models/api'
import { Prefix } from '../../models/prefix'
import api from '../../utils/api'
import PrefixListItem from './PrefixListItem'

interface PrefixListProps {
	prefixes: Prefix[]

	openCreateModal: () => void
	openUpdateModal: (prefix: Prefix) => void
	openExportModal: (prefix: string) => void
	openCreateInvoiceModal: (prefix: Prefix) => void
}
const PrefixList: FC<PrefixListProps> = ({
	prefixes,
	openCreateModal,
	openUpdateModal,
	openExportModal,
	openCreateInvoiceModal,
}) => {
	const [searchQuery, setSearchQuery] = useState('')
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(e.currentTarget.value)
	}

	const [queriedData, setQueriedData] = useState<Prefix[]>([])
	useEffect(() => {
		const fuseSearch = new Fuse<Prefix>(prefixes, {
			keys: ['prefix', 'owner', 'config.game', 'config.agent'],
		})
		if (searchQuery !== '') {
			setQueriedData(fuseSearch.search(searchQuery).map((x) => x.item))
		} else {
			setQueriedData(prefixes)
		}
	}, [prefixes, searchQuery])

	const updateOwner = async (prefix: string) => {
		const ownerQuestion = await swal.fire({
			title: `ผู้ใช้งาน ${prefix}`,
			input: 'text',
			showCancelButton: true,
			inputAttributes: {
				placeholder: 'ผู้ใช้งาน',
			},
		})
		if (!ownerQuestion.isConfirmed) {
			return false
		}

		try {
			const res = await api.post<void, APIResponse>(`/prefixes/${prefix}/owner`, {
				owner: ownerQuestion.value,
			})
			swal.fire('สำเร็จ', res.message, 'success')

			setQueriedData(
				prefixes.map((x) => {
					if (x.prefix === prefix) {
						x.owner = ownerQuestion.value
					}
					return x
				})
			)
		} catch ({ message }) {
			swal.fire('เกิดข้อผิดพลาด', message as string, 'error')
		}
	}
	const togglePrefix = async (prefix: string) => {
		try {
			const res = await api.post<void, APIResponse>(`/prefixes/${prefix}/toggle`)

			// set prefix active to !active
			setQueriedData(
				prefixes.map((x) => {
					if (x.prefix === prefix) {
						x.active = !x.active
					}
					return x
				})
			)
			swal.fire('ดำเนินการสำเร็จ', res.message, 'success')
		} catch ({ message }) {
			swal.fire('เกิดข้อผิดพลาด', message as string, 'error')
		}
	}
	const deletePrefix = async (prefix: string) => {
		const val = await swal.fire({
			title: `คุณต้องการลบ ${prefix.toUpperCase()}?`,
			text: 'เมื่อลบแล้วข้อมูลทั้งหมดจะถูกลบ และไม่สามารถกู้คืนได้',
			showCancelButton: true,
		})
		if (!val.isConfirmed) {
			return false
		}

		try {
			const res = await api.delete<void, APIResponse>(`/prefixes/${prefix}`)
			swal.fire('ดำเนินการสำเร็จ', res.message, 'success')

			setQueriedData(prefixes.filter((_prefix) => _prefix.prefix !== prefix))
		} catch ({ message }) {
			swal.fire('เกิดข้อผิดพลาด', message as string, 'error')
		}
	}

	return (
		<div className="card msn-card">
			<div className="card-header">
				<h1 className="text-white mb-0">เว็บไซต์ทั้งหมด</h1>
				<button
					type="button"
					className="btn btn-primary my-2 my-lg-0 mx-0 ml-lg-3"
					data-toggle="modal"
					data-target="#addPrefix"
					onClick={() => openCreateModal()}
				>
					<i className="fal fa-plus"></i>
					<span>เพิ่ม Prefix</span>
				</button>
				<div className="ml-lg-auto mt-1 mb-3 my-lg-0 mb-lg-0 input-search">
					<i className="fal fa-search"></i>
					<input
						type="text"
						className="form-control form-control-sm"
						placeholder="ค้นหา ..."
						onChange={handleChange}
					/>
				</div>
			</div>
			<div className="table-responsive msn-table">
				<table className="table">
					<thead>
						<tr>
							<th className="w-first-col">Prefix</th>
							<th>Status</th>
							<th>Game</th>
							<th>Agent</th>
							<th>Owner</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{queriedData.length <= 0 ? (
							<tr>
								<td colSpan={5} className="text-center">
									ไม่มีข้อมูล
								</td>
							</tr>
						) : (
							queriedData.map((row) => (
								<PrefixListItem
									key={row.prefix}
									data={row}
									openUpdateModal={openUpdateModal}
									openExportModal={openExportModal}
									updateOwner={updateOwner}
									togglePrefix={togglePrefix}
									deletePrefix={deletePrefix}
									openCreateInvoiceModal={openCreateInvoiceModal}
								/>
							))
						)}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default PrefixList
