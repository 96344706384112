import React, { Fragment, useCallback, useEffect, useState } from 'react'
import swal from 'sweetalert2'
import CreateInvoiceModal from '../../components/Invoices/CreateInvoiceModal'
import CreatePrefix from '../../components/Prefixes/CreatePrefix'
import ExportModal from '../../components/Prefixes/Export/ExportModal'
import PrefixEditModal from '../../components/Prefixes/PrefixEditModal'
import PrefixList from '../../components/Prefixes/PrefixList'
import { Prefix, PrefixFeatureTypes } from '../../models/prefix'
import api from '../../utils/api'

const Prefixes = () => {
	const [features, setFeatures] = useState<Record<PrefixFeatureTypes, string>>()
	const [prefixes, setPrefixes] = useState<Prefix[]>([])
	const fetchData = useCallback(async () => {
		try {
			const res = await Promise.all([
				api.get<void, Record<PrefixFeatureTypes, string>>('/features'),
				api.get<void, Prefix[]>('/prefixes'),
			])

			setFeatures(res[0])
			setPrefixes(res[1].sort((a, b) => (a.active < b.active ? 1 : -1)))
		} catch ({ message }) {
			swal.fire('เกิดข้อผิดพลาด', message as string, 'error')
		}
	}, [])
	useEffect(() => {
		fetchData()
	}, [fetchData])

	const [createPrefixModal, setCreatePrefixModal] = useState(false)
	const [updatePrefixModal, setUpdatePrefixModal] = useState<Prefix>()
	const [exportModal, setExportModal] = useState<string>('')
	const [createInvoiceModal, setCreateInvoiceModal] = useState<Prefix>()
	const toggle = () => setCreatePrefixModal(!createPrefixModal)

	if (!prefixes || !features) {
		return <div>Loading ...</div>
	}

	return (
		<Fragment>
			<div className="row">
				<div className="col-md-12">
					<PrefixList
						prefixes={prefixes}
						openCreateModal={() => toggle()}
						openExportModal={setExportModal}
						openUpdateModal={(prefix) => setUpdatePrefixModal(prefix)}
						openCreateInvoiceModal={(prefix) => setCreateInvoiceModal(prefix)}
					/>
				</div>
			</div>

			<CreatePrefix
				features={features}
				onUpdate={() => fetchData()}
				isOpenModal={createPrefixModal}
				closeCreateModal={() => toggle()}
			/>

			<PrefixEditModal
				features={features}
				prefix={updatePrefixModal}
				closeModal={() => setUpdatePrefixModal(undefined)}
				refreshPrefix={() => fetchData()}
			/>
			<ExportModal prefix={exportModal} close={() => setExportModal('')} />

			<CreateInvoiceModal prefix={createInvoiceModal} closeModal={() => setCreateInvoiceModal(undefined)} />
		</Fragment>
	)
}

export default Prefixes
