import React, { FC } from 'react'
import { Modal } from 'reactstrap'
import { PrefixFeatureTypes } from '../../models/prefix'
import PrefixForm from './PrefixForm'

interface CreatePrefixProps {
	features: Record<PrefixFeatureTypes, string>
	isOpenModal?: boolean

	onUpdate: () => void
	closeCreateModal: () => void
}

const CreatePrefix: FC<CreatePrefixProps> = ({ features, onUpdate, isOpenModal, closeCreateModal }) => {
	return (
		<Modal isOpen={isOpenModal} className="modal-dialog-centered" fade={true} toggle={() => closeCreateModal()}>
			<div className="modal-body">
				<div className="d-flex justify-content-between align-items-center">
					<h1>เพิ่ม Prefix</h1>
					<div className="modal-close">
						<i className="far fa-times" onClick={() => closeCreateModal()}></i>
					</div>
				</div>
				<PrefixForm
					features={features}
					onUpdate={() => {
						onUpdate()
						closeCreateModal()
					}}
				/>
			</div>
		</Modal>
	)
}
export default CreatePrefix
