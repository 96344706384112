import React, { FC } from 'react'
import { Modal } from 'reactstrap'
import { Prefix, PrefixFeatureTypes } from '../../models/prefix'
import PrefixForm from './PrefixForm'

interface PrefixEditModalProps {
	features: Record<PrefixFeatureTypes, string>
	prefix?: Prefix

	closeModal: () => void
	refreshPrefix: () => void
}
const PrefixEditModal: FC<PrefixEditModalProps> = ({ features, prefix, closeModal, refreshPrefix }) => (
	<Modal className="modal-dialog-centered" isOpen={prefix !== undefined} fade={true} toggle={() => closeModal()}>
		<div className="modal-body">
			<div className="d-flex justify-content-between align-items-center">
				<h1>แก้ไขเว็บไซต์ : {prefix?.prefix}</h1>
				<div className="modal-close">
					<i className="far fa-times" onClick={() => closeModal()}></i>
				</div>
			</div>
			<PrefixForm
				prefill={prefix}
				features={features}
				onUpdate={() => {
					refreshPrefix()
					closeModal()
				}}
			/>
		</div>
	</Modal>
)

export default PrefixEditModal
