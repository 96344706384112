import React, { Fragment, useEffect } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router'
import Sidebar from '../components/Layout/Sidebar'
import Bills from '../views/Bills'
import Dashboard from '../views/Dashboard'
import Invoices from '../views/Invoices'
import Prefixes from '../views/Prefixes'

const DashboardLayout = () => {
	const history = useHistory()
	useEffect(() => {
		history.listen(() => {
			if (window.innerWidth < 1200) {
				document.body.classList.remove('g-sidenav-show', 'g-sidenav-pinned')
			}
		})
	}, [history])

	const toggleNavbar = () => {
		if (document.body.classList.contains('g-sidenav-show')) {
			document.body.classList.remove('g-sidenav-show', 'g-sidenav-pinned')
		} else {
			document.body.classList.add('g-sidenav-show', 'g-sidenav-pinned')
		}
	}

	return (
		<Fragment>
			<Sidebar />
			<div className="main-content">
				<div className="msn-top-bar navbar navbar-top border-bottom navbar-expand navbar-dark bg-secondary navbar-expand-lg d-lg-none">
					<div className="container-fluid">
						<div className="sidenav-toggler d-lg-none">
							<div className="sidenav-toggler-inner" onClick={() => toggleNavbar()}>
								<div className="sidenav-toggler-line bg-light" />
								<div className="sidenav-toggler-line bg-light" />
								<div className="sidenav-toggler-line bg-light" />
							</div>
						</div>
						<div className="navbar-brand mx-auto mr-0"><span>MSN</span> MASTER</div>
					</div>
				</div>
				<div className="content">
					<div className="container-fluid mt-4">
						<Switch>
							<Route path="/dashboard" component={Dashboard} />
							<Route path="/prefixes" component={Prefixes} />
							<Route exact path="/invoices" component={Invoices} />
							<Route exact path="/invoices/:id" component={Prefixes} />
							<Route exact path="/bills" component={Bills} />
							<Redirect to="/dashboard" />
						</Switch>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default DashboardLayout
