import React, { FC } from 'react';


interface InvoiceAmountInfoProps {
    dataList: object
}

const InvoiceAmountInfo: FC<InvoiceAmountInfoProps> = ({ dataList }) => {
    const dataArray = Object.entries(dataList);
    const confirm = dataArray.filter(([key, value]) => { return value.status === "confirmed" })
    const pending = dataArray.filter(([key, value]) => { return value.status === "pending" })
    const cancel = dataArray.filter(([key, value]) => { return value.status === "cancelled" })
    let confirmedAmount: number = 0;
    let pendingAmount: number = 0;
    let canceledAmount: number = 0;

    const numberWithCommas = (number: any) => {
        const pattern = /(-?\d+)(\d{3})/;
        number = number.toString();
        while (pattern.test(number)) number = number.replace(pattern, "$1,$2");
        return number;
    }

    confirm.forEach(([key, value]) => { confirmedAmount += value.amount })
    pending.forEach(([key, value]) => { pendingAmount += value.amount })
    cancel.forEach(([key, value]) => { canceledAmount += value.amount })

	return (
        <div className="card-info mb-3">
            <div className="row">
                <div className="col-6 col-md-3 mb-3 mb-md-0 invoice-amount-info">
                    <p>ยอดใบแจ้งนี้ทั้งหมด</p>
                    <h3><span>฿</span> {numberWithCommas(confirmedAmount + pendingAmount)}</h3>
                    <p><small>{confirm.length + pending.length} รายการ</small></p>
                </div>
                <div className="col-6 col-md-3 mb-3 mb-md-0 invoice-amount-info">
                    <p>ชำระเงินแล้ว</p>
                    <h3><span>฿</span> {numberWithCommas(confirmedAmount)}</h3>
                    <p><small>{confirm.length} รายการ</small></p>
                </div>
                <div className="col-6 col-md-3 invoice-amount-info">
                    <p>รอชำระเงิน</p>
                    <h3><span>฿</span> {numberWithCommas(pendingAmount)}</h3>
                    <p><small>{pending.length} รายการ</small></p>
                </div>
                <div className="col-6 col-md-3 invoice-amount-info">
                    <p>ยกเลิก</p>
                    <h3><span>฿</span> {numberWithCommas(canceledAmount)}</h3>
                    <p><small>{cancel.length} รายการ</small></p>
                </div>
            </div>
        </div>
    )
}

export default InvoiceAmountInfo
