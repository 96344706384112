import React, { useCallback, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import BillList from '../../components/Bills/BillList'
import CreateBill from '../../components/Bills/CreateBill'
import { APIResponse } from '../../models/api'
import { Bill } from '../../models/bill'
import api from '../../utils/api'

const Bills = () => {
	const [bills, setBills] = useState<Bill[]>([])
	const [createInvoiceModal, setCreateInvoiceModal] = useState(false);
	const toggle = () => setCreateInvoiceModal(!createInvoiceModal)

	const fetchBills = useCallback(async () => {
		try {
			const res = await api.get<void, Bill[]>('/bills')
			setBills(res)
		} catch ({ message }) {
			Swal.fire('เกิดข้อผิดพลาด', message, 'error')
		}
	}, [])
	useEffect(() => {
		fetchBills()
	}, [fetchBills])

	const confirmBill = async (invoiceID: number) => {
		const result = await Swal.fire({
			title: `ต้องการยืนยันใบแจ้งหนี้ #${invoiceID}?`,
			showCancelButton: true,
		})
		if (!result.isConfirmed) {
			return false
		}

		try {
			const res = await api.post<void, APIResponse>(`/bills/${invoiceID}/confirm`)

			fetchBills()
			Swal.fire('ดำเนินการสำเร็จ', res.message, 'success')
		} catch ({ message }) {
			Swal.fire('เกิดข้อผิดพลาด', message, 'error')
		}
	}
	const cancelBill = async (invoiceID: number) => {
		const result = await Swal.fire({
			title: `ต้องการยกเลิกใบแจ้งหนี้ #${invoiceID}?`,
			showCancelButton: true,
		})
		if (!result.isConfirmed) {
			return false
		}

		try {
			const res = await api.post<void, APIResponse>(`/bills/${invoiceID}/cancel`)

			fetchBills()
			Swal.fire('ดำเนินการสำเร็จ', res.message, 'success')
		} catch ({ message }) {
			Swal.fire('เกิดข้อผิดพลาด', message, 'error')
		}
	}

	return (
		<div className="row">
			<div className="col-md-12">
				<BillList
					bills={bills}
					openCreateModal={() => toggle()}
					confirmBill={confirmBill}
					cancelBill={cancelBill}
				/>
			</div>
			<CreateBill
				refreshBill={fetchBills}
				isOpenModal={createInvoiceModal}
				closeCreateModal={() => toggle()}
			/>
		</div>
	)
}

export default Bills
