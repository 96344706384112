import moment from 'moment'
import React, { FC } from 'react'
import { Bill } from '../../models/bill'

interface PaymentProps {
	bill: Bill
}
const Payment: FC<PaymentProps> = ({ bill }) => (
	<div className="invoice-info row">
		<div className="col-6">
			<address>
				<h3>ชำระเงินโดยการโอนเงินเข้าบัญชีธนาคารด้านล่าง</h3>
				ธนาคารกรุงศรีฯ
				<br />
				เลขที่บัญชี <strong>642-138678-1</strong>
				<br />
				น.ส. ภัคจิรา ไชยวงศ์
				<br />
			</address>
		</div>
		<div className="col-6 text-right">
			<address>
				<h3>โปรดชำระเงินก่อนวันที่</h3>
				{moment(bill.expiredAt).format('DD/MM/YYYY')}
			</address>
		</div>
	</div>
)

export default Payment
