import React, { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface PrefixGameInfoProps {
    dataList: object
}

const PrefixGameInfo: FC<PrefixGameInfoProps> = ({ dataList }) => {
    Chart.register(ChartDataLabels);
    const dataArray = Object.entries(dataList);
    let numberOfGame: any = {};
    let sortable = [];
    let gamesSorted: any = {}
    let gameValues: any = [];

    dataArray.forEach(([key, value]) => {
        let game = value.config.game.toUpperCase()
        numberOfGame[game] = (numberOfGame[game] ?? 0) + 1;
    })

    for (var vehicle in numberOfGame) {
        sortable.push([vehicle, numberOfGame[vehicle]]);
    }

    sortable.sort(function(a, b) { return b[1] - a[1] });
    sortable.forEach(function(game:any) {gamesSorted[game[0]] = game[1] })
    Object.values(gamesSorted).forEach(value => gameValues.push(value));

    const dataGameChart = {
        labels: Object.keys(gamesSorted),
        datasets: [{
            data: gameValues,
            backgroundColor: [
                'rgba(244, 67, 54, 1)',
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(190, 246, 122, 1)',
                'rgba(169, 130, 116, 1)',
                'rgba(142, 172, 187, 1)',
                'rgba(63, 81, 181, 1)',
                'rgba(255, 112, 67, 1)',
            ],
            borderWidth: 0,
            borderRadius: 16,
            borderSkipped: false,
            hoverOffset: 0,
            weight: 0.4,
        }]
    };

    const optionsGameChart = {
        maintainAspectRatio: false,
        scales: {
            y: {
                display: false
            },
            x: {
                ticks: {
                    backdropPadding: 0,
                    padding: 0,
                    color: '#e7ebf0',
                    font: {
                        family: "'DB HelvethaicaX', 'Helvetica', 'Arial', sans-serif",
                        size: 17
                    }
                },
            }
        },
        plugins: {
            title: {
                display: true,
                text: 'เกมส์ทั้งหมด',
                color: '#ffffff',
                font: {
                    family: "'DB HelvethaicaX', 'Helvetica', 'Arial', sans-serif",
                    size: 32,
                },
            },
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            },
            datalabels: {
                display: true,
                anchor: 'end' as const,
                align: 'end' as const,
                color: 'rgba(255, 255, 255, 0.9)',
                font: {
                    family: "'DB HelvethaicaX', 'Helvetica', 'Arial', sans-serif",
                    size: 24,
                },
                offset: -2
            }
        }
    };

	return (
        <div className="card-info mb-3">
            <Bar data={dataGameChart} options={optionsGameChart}/>
        </div>
	)
}

export default PrefixGameInfo
