import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Swal from 'sweetalert2'
import Detail from '../../components/InvoiceView/Detail'
import Header from '../../components/InvoiceView/Header'
import InvoiceInfo from '../../components/InvoiceView/InvoiceInfo'
import Payment from '../../components/InvoiceView/Payment'
import { Invoice, InvoiceInternal } from '../../models/invoice'
import api from '../../utils/api'
import './invoice.scss'

interface InvoiceViewParams {
	invoiceID: string
}
const InvoiceView = () => {
	const params = useParams<InvoiceViewParams>()
	const [invoice, setInvoice] = useState<(Invoice & InvoiceInternal) | null>(null)
	const getInvoice = useCallback(async () => {
		try {
			const res = await api.get<void, Invoice & InvoiceInternal>(`/invoices/${params.invoiceID}`)

			const discountItems = res.items.filter((item) => item.amount < 0)
			res.discount = discountItems.length > 0 ? discountItems[0].amount * -1 : 0
			res.sum = res.items.filter((item) => item.amount > 0).reduce<number>((v, current) => v + current.amount, 0)
			res.total = res.items.reduce<number>((v, current) => v + current.amount, 0)
			setInvoice(res)
		} catch ({ message }) {
			Swal.fire('เกิดข้อผิดพลาด', message, 'error')
		}
	}, [params])
	useEffect(() => {
		getInvoice()
	}, [getInvoice])

	const convertToPDF = () => {
		window
			.html2pdf()
			.set({
				filename: `${invoice?.prefix}-${moment().format('YYYY-MM-DD')}.pdf`,
				html2canvas: {
					scale: 4,
				},
			})
			.from(document.getElementById('invoice'))
			.save()
	}

	if (!invoice) {
		return <div>Loading ...</div>
	}
	return (
		<div className="container">
			<div className="wrapper">
				<div id="invoice" className="invoice">
					<Header invoice={invoice} />
					<InvoiceInfo invoice={invoice} />
					<Detail invoice={invoice} />
					<Payment invoice={invoice} />
				</div>
			</div>

			<div className="btn-bottom">
				<button type="button" className="btn btn-light" onClick={() => convertToPDF()}>
					Download PDF
				</button>
			</div>
		</div>
	)
}

export default InvoiceView
