import React, { FC } from 'react'
import BillForm from './BillForm'
import { Modal } from 'reactstrap'

interface CreateBillProps {
	isOpenModal?: boolean

	refreshBill: () => void
	closeCreateModal: () => void
}
const CreateBill: FC<CreateBillProps> = ({ refreshBill, isOpenModal, closeCreateModal }) => {
	return (
		<Modal isOpen={isOpenModal} className="modal-dialog-centered" fade={true} toggle={() => closeCreateModal()}>
			<div className="modal-body">
				<div className="d-flex justify-content-between align-items-center">
					<h1>ออกใบแจ้งหนี้</h1>
					<div className="modal-close">
						<i className="far fa-times" onClick={() => closeCreateModal()}></i>
					</div>
				</div>
				<BillForm refreshBill={() => {
					refreshBill()
					closeCreateModal()
				}} />
			</div>
		</Modal>
	)
}

export default CreateBill
