import React, { FC } from 'react'
import { Prefix } from '../../models/prefix'

interface PrefixListItemProps {
	data: Prefix

	openUpdateModal: (prefix: Prefix) => void
	openExportModal: (prefix: string) => void
	updateOwner: (prefix: string) => void
	togglePrefix: (prefix: string) => void
	deletePrefix: (prefix: string) => void
	openCreateInvoiceModal: (prefix: Prefix) => void
}
const PrefixListItem: FC<PrefixListItemProps> = ({
	data,
	openUpdateModal,
	openExportModal,
	updateOwner,
	togglePrefix,
	deletePrefix,
	openCreateInvoiceModal,
}) => (
	<tr>
		<td>
			<h4>{data.prefix}</h4>
		</td>
		<td>
			{data.active ? (
				<span className="d-block badge badge-success badge-sm">เปิดใช้งาน</span>
			) : (
				<span className="d-block badge badge-danger badge-sm">ปิดใช้งาน</span>
			)}
		</td>
		<td>{data.config.game.toUpperCase()}</td>
		<td>{data.config.agent.toUpperCase()}</td>
		<td className="text-primary" onClick={() => updateOwner(data.prefix)}>
			<i className="fad fa-user" /> &nbsp; {data.owner}
		</td>
		<td className="text-right">
			{data.active ? (
				<button type="button" className="btn btn-danger btn-sm" onClick={() => togglePrefix(data.prefix)}>
					ปิดใช้งาน
				</button>
			) : (
				<button type="button" className="btn btn-success btn-sm" onClick={() => togglePrefix(data.prefix)}>
					เปิดใช้งาน
				</button>
			)}
			<button type="button" className="btn btn-info btn-sm" onClick={() => openUpdateModal(data)}>
				แก้ไข
			</button>
			<button type="button" className="btn btn-default btn-sm" onClick={() => openExportModal(data.prefix)}>
				Export
			</button>
			<button type="button" className="btn btn-warning btn-sm" onClick={() => openCreateInvoiceModal(data)}>
				ออกใบแจ้งหนี้
			</button>
			<button type="button" className="btn btn-outline-danger btn-sm" onClick={() => deletePrefix(data.prefix)}>
				ลบ
			</button>
		</td>
	</tr>
)

export default PrefixListItem
