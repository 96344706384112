import React, { useEffect } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import AuthWrapper from './components/AuthWrapper'
import AppLayout from './layouts/App'
import Auth from './layouts/Auth'
import BillView from './views/BillView'
import InvoiceView from './views/InvoiceView'

const Logout = () => {
	useEffect(() => {
		localStorage.removeItem('x-master-token')
	}, [])
	return <Redirect to="/login" />
}

function App() {
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/login" component={Auth} />

				<AuthWrapper>
					<Switch>
						<Route exact path="/invoices/:invoiceID" component={InvoiceView} />
						<Route exact path="/bills/:billID" component={BillView} />
						<Route path="/logout" component={Logout} />

						<Route component={AppLayout} />
					</Switch>
				</AuthWrapper>
			</Switch>
		</BrowserRouter>
	)
}

export default App
