import moment from 'moment'
import React, { FC } from 'react'
import { Invoice } from '../../models/invoice'

interface InvoiceInfoProps {
	invoice: Invoice
}
const InvoiceInfo: FC<InvoiceInfoProps> = ({ invoice }) => (
	<div className="invoice-info row">
		<div className="col-6">
			<address>
				<h3>รหัสลูกค้า</h3>
				MSN-{invoice.prefix}
				<br />
			</address>
			<address>
				<h3>ออกใบแจ้งหนี้ให้กับ</h3>
				<span id="company_name">{invoice.prefix}</span>
			</address>
		</div>
		<div className="col-6 text-right">
			<address>
				<h3>วันที่ออกใบแจ้งหนี้</h3>
				{moment(invoice.createdAt).format('DD/MM/YYYY')}
				<br />
			</address>
			<address>
				<h3>ชำระเงินให้กับ</h3>
				MSN Bet Co., Ltd
			</address>
		</div>
	</div>
)

export default InvoiceInfo
