import { useFormik } from 'formik'
import React, { FC } from 'react'
import { Modal } from 'reactstrap'
import swal from 'sweetalert2'
import { API_ENDPOINT } from '../../../constant'
import api from '../../../utils/api'
import { exportMemberOptions, ExportResponse } from './types'

interface ExportModalProps {
	prefix: string

	close: () => void
}
const ExportModal: FC<ExportModalProps> = ({ prefix, close }) => {
	const { isSubmitting, setFieldValue, handleSubmit } = useFormik({
		initialValues: {
			endPoint: API_ENDPOINT,
			options: {},
		},
		onSubmit: async (values) => {
			try {
				const res = await api.post<void, ExportResponse>(`/prefixes/${prefix}/export`, values)

				window.location.href = res.downloadURL
			} catch ({ message }) {
				swal.fire('เกิดข้อผิดพลาด', message as string, 'error')
			}
		},
	})

	return (
		<Modal className="modal-dialog-centered" isOpen={!!prefix} fade={true} toggle={close}>
			<div className="modal-body">
				<div className="d-flex justify-content-between align-items-center">
					<h1>Export : {prefix}</h1>
					<div className="modal-close">
						<i className="far fa-times" onClick={close}></i>
					</div>
				</div>
				<form autoComplete="off" onSubmit={handleSubmit}>
					<div className="form-group">
						{Object.keys(exportMemberOptions).map((optKey) => (
							<div className="mb-2" key={optKey}>
								<label className="msn-checkbox">
									{exportMemberOptions[optKey]}
									<input
										type="checkbox"
										name={`options.${optKey}`}
										onChange={(e) => setFieldValue(`options.${optKey}`, e.currentTarget.checked)}
									/>
									<span className="checkmark"></span>
								</label>
							</div>
						))}
					</div>
					<button type="submit" className="btn btn-primary mx-auto" disabled={isSubmitting}>
						ยืนยัน
					</button>
				</form>
			</div>
		</Modal>
	)
}

export default ExportModal
